"use client";

import useHasHydrated from "@/lib/hooks/useHasHydrated";
import useOutsideClick from "@/lib/hooks/useOutsideClick";
import clsx from "clsx";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styles from "./modal.module.css";
const Modal = ({
  children,
  isOpen,
  callback,
  className
}: {
  children: ReactNode;
  isOpen: boolean;
  callback: () => void;
  className?: string;
}) => {
  const ref = useOutsideClick(callback);

  const isHydrated = useHasHydrated();

  if (!isOpen) {
    return null;
  }

  if (isHydrated) {
    return (
      <>
        {/* @ts-ignore */}
        {
          /* @ts-ignore */ createPortal(
            <div className={clsx(styles.modal, className)} ref={ref}>
              {/* @ts-ignore */ children}
            </div>, // @ts-ignore
            document.body // @ts-ignore
          )
          /* @ts-ignore */
        }
      </>
    );
  }

  return null;
};
export default Modal;
